import React, { useState, useEffect } from 'react';

import css from './blog-feed.module.css';

import Image from '../Image';
import Button from '../Button';

import { Link } from 'gatsby';

const PAGINATION = 8;

function BlogFeed(props) {
  const { posts } = props;
  const [page, setPage] = useState(1);
  const [activePosts, setActivePosts] = useState(
    posts && posts.length ? [...posts.slice(0, PAGINATION * page)] : []
  );

  useEffect(() => {
    setActivePosts([...posts.slice(0, PAGINATION * page)]);
  }, [page]);

  return (
    <div className={css.feed}>
      {activePosts.length ? (
        <>
          {activePosts.map(post => {
            const { slug, title, featuredImage } = post;
            return (
              <Link
                key={post.slug}
                to={`/blog/${slug}`}
                className={css.postLink}
              >
                <h2 className={css.postTitle}>{title}</h2>

                <Image src={featuredImage} alt={title} />
              </Link>
            );
          })}

          {posts.length > PAGINATION && (
            <Button onClick={() => setPage(page + 1)}>Load More</Button>
          )}
        </>
      ) : (
        <h2>No posts found</h2>
      )}
    </div>
  );
}

export default BlogFeed;
