import React from 'react';
import { graphql } from 'gatsby';

import Page from '../components/Page';
import SEO from '../components/SEO';
import BlogFeed from '../components/BlogFeed';

const TITLE = 'Blog';

function Blog({ data }) {
  const posts = data.posts.edges.map(({ node }) => {
    const { slug, title, featuredImage } = node;

    return {
      slug,
      title,
      featuredImage: featuredImage.sizes.src
    };
  });
  const body = (
    <>
      <SEO title={TITLE} />

      <BlogFeed posts={posts} />
    </>
  );

  return <Page title={TITLE} body={body} />;
}

export default Blog;

export const query = graphql`
  query BlogQuery {
    posts: allContentfulPost {
      edges {
        node {
          title
          slug
          featuredImage {
            sizes {
              src
            }
          }
        }
      }
    }
  }
`;
